import { BrandConfig } from '../config';
import { CountryISO } from 'interfaces/countryInterface';
import { CLIENT_BRAND_LABEL, CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrandInterface';
import darkTacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import whiteTacklitLogo from 'assets/images/tacklit-logo-white.svg';
import { InfoFieldType, PaymentOption } from 'interfaces/signupConfigInterface';

export const tacklitConfig: BrandConfig = {
  accountId: '60efad738399fd000ad6eae6',
  brand: CLIENT_BRAND_LOWER_CASE,
  slugUrl: 'someone-health',
  favIcon: '',
  defaultTimezone: 'Australia/Melbourne',
  metaInfo: {
    title: CLIENT_BRAND_LABEL.TACKLIT,
    desc: 'Tacklit super best'
  },
  logo: {
    darkLogo: darkTacklitLogo,
    whiteLogo: whiteTacklitLogo
  },
  tracking: {
    gtmId: '',
    ga4Id: ''
  },
  region: CountryISO.AU,
  link: {
    contactUsText:
      'Our team are available and happy to answer your questions Monday - Friday 9am - 5pm (4.30pm on a Friday).',
    contactUsUrl: 'https://www.tacklit.com'
  },
  contactLink: {
    phoneNumber: '023 0443 2343',
    email: 'hello@tacklit.com',
    address: '1 Main Street, London EC1R 4RT'
  },
  pageConfig: {
    headerMenu: {
      allowEditStripeCard: true,
      useDarkLogo: true,
      allowBookNewAppointment: true
    },
    engageFooter: {
      useDarkLogo: true,
      footerDescription: 'Mindbalance provide multiple '
    }
  },
  faqConfig: {
    title: 'Frequently Asked Questions',
    desc: "We're here to listen and help you through whatever mental health challenges you're facing.",
    faqData: [
      {
        title: 'Can I change my appointments?',
        desc: `Yes! We know sometimes plans need to change and so have a flexible policy that allows you to reschedule an appointment as long as you give us at least 24 hours notice.  You can manage your appointments in your someone.health account.`
      },
      {
        title: 'Do I need a Medicare Card?',
        desc: `We don’t need the physical card, but you will need to know your Medicare card number.<br/>If you don’t already have a Medicare card you can find out about enrolling into Medicare with Services Australia. If you’ve lost your card or forgotten your card number, you can jump onto MyGov to find your details.`
      },
      {
        title: 'Do I need a GP referral?',
        desc: `In order to access your two bulk billed and receive rebates on additional appointments you do need a GP referral. You can either upload a referral from your own GP or you can book a video appointment with a someone.health GP.<br/><br/>You will be able to select a time to jump on a video call with one of our GPs once you’ve chosen your Psychologist appointment time. Our GP appointments are fully bulk billed and only take 20 minutes.`
      },
      {
        title: 'What costs are involved?',
        desc: `Your first two sessions are fully bulk billed. For sessions 3 to 10 you’ll need to pay the full amount first and then you will get your Medicare rebate soon after the appointment (if you are eligible). This means the amount you’ll pay after the Medicare rebate is just $40.<br/><br/>The only other fee is if you don't show up. Hopefully this doesn’t happen to you. But if you do miss an appointment or don’t give us at least 24 hours heads-up, there’s a $100 fee.`
      }
    ]
  },
  engageConfig: {
    timer: 0,
    createAsRecordOnly: false,
    paymentMethod: {
      paymentOption: PaymentOption.SaveCardOnly
    },
    infoFields: [
      InfoFieldType.EmergencyContact,
      InfoFieldType.GPInformation,
      InfoFieldType.Medicare,
      InfoFieldType.Address,
      InfoFieldType.ReferralInformation,
      InfoFieldType.CommunicationPreference
    ],
    welcomeMessage: 'Welcome to Tacklit'
  }
};
