import { combineReducers } from 'redux';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import { patientProfileServicesApiSlice } from './services/patientProfileServiceApisSlice';
import clientTimeZoneSlice from './slice/clientTimeZoneSlice';

const rootReducer = combineReducers({
  // add reducers here
  clientTimeZoneSlice: clientTimeZoneSlice,
  [checkInServicesApiSlice.reducerPath]: checkInServicesApiSlice.reducer,
  [clinicianProfileServicesApiSlice.reducerPath]: clinicianProfileServicesApiSlice.reducer,
  [scheduleServicesApiSlice.reducerPath]: scheduleServicesApiSlice.reducer,
  [patientProfileServicesApiSlice.reducerPath]: patientProfileServicesApiSlice.reducer
});

export default rootReducer;
