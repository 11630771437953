export interface BookingRulesChildProps {
  available: boolean;
}

export enum DeliveryType {
  FaceToFace = 'faceToFace',
  VideoCall = 'videoCall',
  PhoneCall = 'phoneCall',
  PhoneCallDialClient = 'phoneCallDialClient',
  Other = 'other'
}

export interface OtherInstructions {
  title: string;
  instructions?: string;
}

export interface ActiveDays {
  Sunday: boolean;
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
}

export enum BookingType {
  BOOKING = 'booking',
  RESCHEDULE = 'reschedule'
}

export enum AppointmentBookingClaimType {
  BulkBill = 'bulkBill',
  Rebate = 'rebate',
  VTPHNA = 'VTPHNA',
  WISE = 'WISE'
}

export interface AvailabilityAppointmentTypes {
  _id: string;
  clinicianId?: string;
  name?: string;
  description?: string;
  duration?: number;
  rate?: number;
  assignedClinicians?: string[];
  deliveryOptions: DeliveryType[];
  otherInstructions?: OtherInstructions;
  activeDays: ActiveDays;
  paymentRequired?: boolean;
  slotCount?: number;
  bookingRules?: {
    new: BookingRulesChildProps;
    existing: BookingRulesChildProps;
  };
  isAdvisory?: boolean;
  shouldHideFromListing?: boolean;
  programId?: string;
  claimType?: AppointmentBookingClaimType;
}

export const DELIVERY_TYPE_LABELS: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'Face to face',
  [DeliveryType.PhoneCall]: 'Telehealth phone call',
  [DeliveryType.PhoneCallDialClient]: 'Outbound phone call',
  [DeliveryType.VideoCall]: 'Telehealth video call',
  [DeliveryType.Other]: 'Other'
};

export const DELIVERY_TYPE_ICONS: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'face',
  [DeliveryType.PhoneCall]: 'call',
  [DeliveryType.PhoneCallDialClient]: 'call',
  [DeliveryType.VideoCall]: 'video_camera_front',
  [DeliveryType.Other]: 'connect_without_contact'
};

export const DELIVERY_TYPE_ICONS_OFF: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'face_retouching_off',
  [DeliveryType.PhoneCall]: 'phone_disabled',
  [DeliveryType.PhoneCallDialClient]: 'phone_disabled',
  [DeliveryType.VideoCall]: 'videocam_off',
  [DeliveryType.Other]: 'connect_without_contact'
};
