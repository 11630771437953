import { FitTimesList } from './fitFilter';
import { Options } from '../common/general';

export const AVAILABLE_TIMES_LABELS: Record<FitTimesList, string> = {
  morning: 'Morning (before Midday)',
  afternoon: 'Afternoon (12PM - 6PM)',
  evening: 'Evening (after 6PM)'
};

export const generateAvailableTimesList = (): Options[] => {
  return Object.keys(FitTimesList)
    .filter((listObj) =>
      [FitTimesList.morning, FitTimesList.afternoon, FitTimesList.evening].includes(listObj as FitTimesList)
    )
    .map((listObj) => ({
      value: listObj,
      label: AVAILABLE_TIMES_LABELS[listObj as keyof typeof FitTimesList]
    }));
};
