import styles from './SlotPicker.module.scss';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import TimeSlot from 'components/TimeSlot/TimeSlot';
import dayjs from 'utils/dayjsExtended';
import { TimeSlotsWithDateInterface } from 'interfaces/Appointment/appointment';

interface SlotPickerProps {
  selectedDate: string;
  slots?: TimeSlotsWithDateInterface[];
  selectedSlots: TimeSlotsWithDateInterface[];
  onClickSlot: (slot: TimeSlotsWithDateInterface) => void;
  isLoading: boolean;
  remainSlots: number;
  className?: string;
}

export const isSlotSelected = ({
  selectedSlots,
  slot
}: {
  selectedSlots: TimeSlotsWithDateInterface[];
  slot: TimeSlotsWithDateInterface;
}) =>
  selectedSlots.some(
    (selectedSlot) =>
      selectedSlot.isAvailable === true &&
      selectedSlot.startDateTime === slot.startDateTime &&
      selectedSlot.endDateTime === slot.endDateTime
  );

const SlotPicker = ({
  selectedDate,
  slots,
  selectedSlots,
  onClickSlot,
  isLoading,
  remainSlots,
  className
}: SlotPickerProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingHeaderWrapper}>
        <Skeleton.Input active className={styles.loadingTitle} />
      </div>
      <div className={styles.loadingSlotWrapper}>
        {[...Array(9)].map((obj, i) => (
          <Skeleton.Input key={i} active className={styles.loadingSlot} />
        ))}
      </div>
    </div>
  ) : (
    <div className={classNames(styles.container, className)}>
      <div className={styles.dateTitle}>{dayjs(selectedDate).format('dddd DD MMMM YYYY')}</div>
      {slots?.length ? (
        <div className={styles.timeSlotWrapper}>
          {slots.map((slot, index) => (
            <div
              className={styles.timeSlot}
              key={index}
              onClick={() => (isSlotSelected({ selectedSlots, slot }) || remainSlots > 0) && onClickSlot(slot)}
            >
              <TimeSlot
                time={slot.startTime}
                className={styles.timeSlot}
                active={isSlotSelected({ selectedSlots, slot })}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noSlotWrapper}>No available time slots match your selected filters</div>
      )}
    </div>
  );
};

export default SlotPicker;
