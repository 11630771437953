import { CountryConfigInterface } from './config';

export const configSA: CountryConfigInterface = {
  countryCode: 'sa',
  moneyIcon: 'monetization_on',
  currencySymbol: 'ر.س',
  currencyCode: 'sar',
  taxLabel: 'TAX',
  taxRateLabel: 'VAT',
  codeLabel: '',
  mobileCountryCode: '966',
  defaultTimezone: 'Asia/Riyadh',
  contactMaskNumber: '050 123 4567',
  contactMaskNumberWithX: '05x xxx xxxx',
  emergencyNumber: '997',
  lifeLineNumber: '920029855',
  practiceSettings: {
    registrationNumberLabel: 'CR Number'
  }
};
