import { scheduleServicesApiSlice, SSTagTypes } from 'reduxToolkit/services/scheduleServicesApiSlice';
import { AvailabilityAppointmentTypes, BookingType } from 'interfaces/Appointment';
import queryString from 'query-string';
import {
  AppointmentAvailabilityWithTimeZone,
  AppointmentAvailabilityWithTimeZoneResponse
} from 'interfaces/Appointment/appointment';
import { massageTimeSlot } from 'utils/massageTimeSlotsByTimeZone';

interface GetAppointmentTypesRequest {
  accountId: string;
  clinicianId?: string;
  excludeAllAssignmentMode?: string;
  excludeHideFromListing?: string;
  isGP?: boolean;
}

interface GetPublicAvailabilityByAppointmentTypeIdRequest {
  appointmentTypeId: string;
  accountId: string;
  from: string;
  to: string;
  type: BookingType;
  clinicianId?: string;
}

export const appointmentTypes = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentTypes: builder.query<AvailabilityAppointmentTypes[], GetAppointmentTypesRequest>({
      query: (params) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/availability/appointment-types/public${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.AppointmentTypes]
    }),
    getAvailabilityAppointmentsByAppointmentTypeId: builder.query<
      AppointmentAvailabilityWithTimeZoneResponse,
      GetPublicAvailabilityByAppointmentTypeIdRequest
    >({
      query: ({ appointmentTypeId, accountId, from, to, type, clinicianId }) => {
        const queryParam = queryString.stringify({ accountId, date: from, to, type, clinicianId });
        return {
          url: `/appointment-types/${appointmentTypeId}/availabilities/public?${queryParam}`
        };
      },
      transformResponse: (response: AppointmentAvailabilityWithTimeZone, _meta, arg) => {
        const { appointmentType, timeSlots } = response;
        return {
          appointmentType,
          timeSlots: massageTimeSlot(timeSlots)
        };
      },
      providesTags: [SSTagTypes.AvailabilityAppointmentsByAppointmentTypeId]
    })
  })
});

export const { useGetAppointmentTypesQuery, useGetAvailabilityAppointmentsByAppointmentTypeIdQuery } = appointmentTypes;
