import { TimeSlotsWithDateInterface, TimeSlotWithoutDate } from '../interfaces/Appointment/appointment';
import dayjs from 'utils/dayjsExtended';

const massageNewTimeSlot = ({
  timeSlotList
}: {
  timeSlotList?: TimeSlotWithoutDate[];
}): TimeSlotsWithDateInterface[] => {
  if (!timeSlotList) return [];

  return timeSlotList.map(({ isAvailable, startTime, endTime, clinicianId, startDateTime, endDateTime }) => ({
    isAvailable: isAvailable,
    clinicianId: clinicianId,
    date: dayjs(startDateTime).format('YYYY-MM-DD'),
    endDate: dayjs(endDateTime).format('YYYY-MM-DD'),
    startTime: startTime,
    endTime: endTime,
    startDateTime: startDateTime,
    endDateTime: endDateTime
  }));
};

export const massageTimeSlot = (
  timeSlotList: Record<string, { isAvailable: boolean; timeSlots: TimeSlotWithoutDate[] }>
): TimeSlotsWithDateInterface[] => {
  return Object.entries(timeSlotList).flatMap(([date, { isAvailable, timeSlots }]) =>
    isAvailable
      ? massageNewTimeSlot({
          timeSlotList: timeSlots
        })
      : []
  );
};
