import { InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './DeliveryTypeRadio.module.scss';
import { RadioOptions } from 'interfaces/common/general';

export interface DeliveryTypeRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  options: RadioOptions[];
  labelClass?: string;
  radioClass?: string;
  radioClassName?: string;
  activeRadioClassName?: string;
  radioLabelClass?: string;
  radioWrapperClass?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  vertical?: boolean;
  variant?: string;
  customOnChange?: (value?: RadioOptions) => void;
}

const DeliveryTypeRadio = ({
  className,
  label,
  name,
  options,
  labelClass,
  radioClass,
  radioLabelClass,
  radioWrapperClass,
  hasError,
  noSpacing,
  value: formValue,
  vertical,
  radioClassName,
  activeRadioClassName,
  variant,
  customOnChange,
  ...props
}: DeliveryTypeRadioProps) => {
  const radioClasses = classnames(
    hasError && styles.error,
    noSpacing && styles.noSpacing,
    variant ? styles[variant] : styles.radioBtn,
    radioClass
  );

  const handleOnChange = (selectedValue: string) => {
    const getCompleteValue = options.find((option) => option.id === selectedValue);
    customOnChange?.(getCompleteValue);
  };

  return (
    <div id={name} className={classnames(className)}>
      {label && <div className={classnames(styles.label, labelClass)}>{label}</div>}
      <div className={classnames(styles.radio, vertical && styles.vertical, radioWrapperClass)}>
        {options.map(({ label, value, id: optionsId, disabled }, index) => {
          return (
            <label
              htmlFor={`radio-${name}-${value}-${optionsId}`}
              className={classnames(
                radioClasses,
                radioClassName,
                (optionsId === formValue || formValue === value) && activeRadioClassName,
                disabled && styles.disabled
              )}
              key={index}
            >
              <input
                type={'radio'}
                id={`radio-${name}-${value}-${optionsId}`}
                name={`${name}-${optionsId}`}
                value={optionsId || value}
                checked={optionsId === formValue || value === formValue || false}
                onChange={(e) => {
                  handleOnChange(e.target.value);
                  return e;
                }}
                disabled={disabled}
                {...props}
              />
              <div className={classnames(styles.label, radioLabelClass)}>{label}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default DeliveryTypeRadio;
