import { PractitionerDetailsInterface } from 'interfaces/Engage/publicProfile';
import styles from './PsychologistCard.module.scss';
import ClinicianAvatar from 'components/EngageComponent/PsychologistCard/ClinicianAvatar/ClinicianAvatar';
import { MEDICARE_ROLES_OPTIONS } from 'interfaces/Clinician/enums';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';
import PsychologistSpecialisation from 'pages/Engage/PsychologistListing/components/PsychologistSpecialisation/PsychologistSpecialisation';
import { getMentalHealthLabels } from '../../../pages/Engage/PsychologistListing/utils/utils';
import { useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { ENGAGE_ROUTES_LIST } from 'routes/constants';
import ShowMoreText from 'react-show-more-text';
import PsychologistAvailabilityCalendar from './PsychologistAvailabilityCalendar/PsychologistAvailabilityCalendar';
import { useAuth0 } from '@auth0/auth0-react';
import { FitDeliveryList } from '../../../interfaces/Engage/fitFilter';

interface PsychologistCardProps {
  clinicianDetail: PractitionerDetailsInterface;
}

export const PRONOUN_OPTIONS = {
  heHim: 'He / Him',
  sheHer: 'She / Her',
  theyThem: 'They / Them'
};

const { PRACTITIONER } = ENGAGE_ROUTES_LIST;

const PsychologistCard = ({ clinicianDetail }: PsychologistCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated } = useAuth0();
  // const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] = useState<MentalHealthList[]>([]);
  const [cardWidth, setCardWidth] = useState(400);

  const isNewClient = !isAuthenticated;

  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        const { width } = cardRef.current.getBoundingClientRect();
        setCardWidth(width);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
    clinicianId: clinicianDetail.slugUrl
  });

  return (
    <div ref={cardRef} className={styles.container}>
      <div className={styles.badge}>
        {clinicianDetail?.medicare?.role ? (
          <>
            {MEDICARE_ROLES_OPTIONS[clinicianDetail.medicare.role as keyof typeof MEDICARE_ROLES_OPTIONS]}
            <span className={`material-icons-outlined ${styles.verifiedIcon}`}>verified</span>
          </>
        ) : null}
      </div>

      <div className={styles.content}>
        <div className={styles.avatarSection}>
          <ClinicianAvatar className={styles.avatar} profileImg={clinicianDetail.avatar} detailPath={detailsPagePath} />
        </div>

        <div className={styles.basicInfo}>
          <div className={styles.rowWrapper}>
            <div className={styles.name}>{clinicianDetail.name}</div>
            <div className={styles.pronouns}>
              {clinicianDetail.pronouns
                ? `${PRONOUN_OPTIONS[clinicianDetail.pronouns as keyof typeof PRONOUN_OPTIONS]}`
                : ''}
            </div>
          </div>

          <div className={styles.rowWrapper}>
            {clinicianDetail.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
              <i className={`material-icons ${styles.deliveryIcon}`}>videocam</i>
            )}
            {clinicianDetail.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
              <i className={`material-icons ${styles.deliveryIcon}`}>call</i>
            )}

            <div className={styles.language}>
              {clinicianDetail.helmControl?.deliveryModes &&
                clinicianDetail.helmControl.deliveryModes?.length > 0 &&
                clinicianDetail.helmControl.bonaFides?.languages?.active &&
                clinicianDetail.helmControl.bonaFides?.languages.value &&
                clinicianDetail.helmControl.bonaFides?.languages.value?.length > 0 && (
                  <div className={styles.inLabel}>in</div>
                )}
              {clinicianDetail.helmControl?.bonaFides?.languages?.value?.map((languageObj, index) => (
                <div key={index} className={styles.text}>
                  {LANGUAGE_COLLECTION[languageObj as keyof typeof LANGUAGE_COLLECTION]}
                </div>
              ))}
            </div>
          </div>
        </div>
        {clinicianDetail.bio && (
          <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className={styles.details}
            anchorClass="readMore"
            onClick={() => {
              console.log('ahhhhh');
            }}
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            {clinicianDetail.helmControl.shortDescription}
          </ShowMoreText>
        )}
        {clinicianDetail.helmControl.primarySpecialisations &&
          clinicianDetail.helmControl.primarySpecialisations.length > 0 && (
            <PsychologistSpecialisation
              specialisationsDetails={getMentalHealthLabels(clinicianDetail.helmControl.primarySpecialisations)}
              // selectedValue={getMentalHealthLabels(selectedMentalHealthConcern)}
            />
          )}
        <PsychologistAvailabilityCalendar
          practitionerDetails={clinicianDetail}
          detailPath={detailsPagePath}
          nextAvailabilityDetails={clinicianDetail.nextAvailabilities?.filter((item) =>
            isNewClient
              ? item.appointmentTypeBookingRules?.new.available
              : item.appointmentTypeBookingRules?.existing.available
          )}
          noFilter={false}
          cardWidth={cardWidth}
        />
      </div>
    </div>
  );
};

export default PsychologistCard;
