import styles from './MultiSelectBadge.module.scss';
import classNames from 'classnames';

export interface MultiSelectBadgeProps {
  id: string;
  value: string;
  selected?: boolean;
  className?: string;
  onRemoveBadge?: (value: string) => void;
}

const MultiSelectBadge = ({ id, value, selected, className, onRemoveBadge }: MultiSelectBadgeProps) => (
  <div className={classNames(selected ? styles.selected : styles.badge, className)}>
    {value}
    <div
      onClick={(e) => {
        e.stopPropagation();
        onRemoveBadge?.(id);
      }}
      className={`material-symbols-outlined ${styles.deleteIcon}`}
    >
      remove_circle
    </div>
  </div>
);

export default MultiSelectBadge;
