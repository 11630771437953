import { CountryConfigInterface } from './config';

export const configAU: CountryConfigInterface = {
  countryCode: 'au',
  moneyIcon: 'monetization_on',
  currencySymbol: '$',
  currencyCode: 'aud',
  taxLabel: 'TAX',
  taxRateLabel: 'GST',
  codeLabel: 'BSB Code',
  mobileCountryCode: '61',
  defaultTimezone: 'Australia/Melbourne',
  contactMaskNumber: '0482 666 666',
  contactMaskNumberWithX: '04xx xxx xxx',
  emergencyNumber: '000',
  lifeLineNumber: '13 11 14',
  practiceSettings: {
    registrationNumberLabel: 'ABN'
  }
};
