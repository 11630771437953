import styles from './AvailabilityCalendar.module.scss';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import './MobiscrollCustom.scss';
import dayjs from 'utils/dayjsExtended';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useGetAvailabilityAppointmentsByAppointmentTypeIdQuery } from 'reduxToolkit/endpoints/scheduleServices/appointmentType';
import { IS_DEVELOPMENT } from 'utils/featureToggle';
import { AvailabilityAppointmentTypes, BookingType } from 'interfaces/Appointment';
import { clientTimeZone } from 'reduxToolkit/slice/clientTimeZoneSlice';
import { useAppSelector } from 'reduxToolkit/hooks';
import SlotPicker, { isSlotSelected } from '../SlotPicker/SlotPicker';
import { TimeSlotsWithDateInterface } from 'interfaces/Appointment/appointment';
import { Options } from 'interfaces/common/general';
import { FitTimesList } from 'interfaces/Engage/fitFilter';

export const displayMonth = 6;
const maxDate = dayjs().add(displayMonth, 'month');

const allDates = (() => {
  let x = dayjs();
  const res: string[] = [];
  while (x.isBefore(maxDate)) {
    res.push(x.format('YYYY-MM-DD'));
    x = x.add(1, 'day');
  }
  return res;
})();

export const convertTimeSlotTimeZone = (
  timeSlotObj: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = dayjs.utc(timeSlotObj.startDateTime).tz(clientTimeZone);
  const clientEndTimeZone = dayjs.utc(timeSlotObj.endDateTime).tz(clientTimeZone);

  return {
    isAvailable: timeSlotObj.isAvailable,
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    endDate: clientEndTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA'),
    startDateTime: clientStartTimeZone.toDate(), // Ensure it's a Date object
    endDateTime: clientEndTimeZone.toDate(), // Ensure it's a Date object
    clinicianId: timeSlotObj.clinicianId
  };
};

const getFilteredSlots = (slots: TimeSlotsWithDateInterface[], timeRanges?: Options[]) => {
  if (!timeRanges || timeRanges.length === 0) return slots;

  return slots.filter((slot) =>
    timeRanges?.some((range) => {
      switch (range.value) {
        case FitTimesList.morning:
          return slot.endTime.endsWith('AM') || slot.endTime === '12:00PM';
        case FitTimesList.afternoon:
          return slot.startTime.endsWith('PM') && (slot.endTime < '06:01PM' || slot.endTime.startsWith('12'));
        case FitTimesList.evening:
          return slot.startTime.endsWith('PM') && slot.startTime > '05:59PM' && !slot.startTime.startsWith('12');
        default:
          return false;
      }
    })
  );
};

interface AvailabilityCalendarProps {
  accountId: string;
  clinicianId: string;
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  timeRangeFilter: Options[];
}

const AvailabilityCalendar = ({
  accountId,
  clinicianId,
  selectedAppointmentType,
  timeRangeFilter
}: AvailabilityCalendarProps) => {
  const { selectedClientTimeZone } = useAppSelector(clientTimeZone);

  const maxDate = dayjs().add(6, 'month');
  const minDate = dayjs();

  const from = dayjs().format('YYYY-MM-DD');
  const to = IS_DEVELOPMENT
    ? dayjs().add(1, 'week').format('YYYY-MM-DD')
    : dayjs().add(12, 'month').format('YYYY-MM-DD');

  const { search } = useLocation();
  const { selectedDateTime: selectedDateTimeParams } = queryString.parse(search);

  const [selectedDate, setSelectedDate] = useState<string>(
    (selectedDateTimeParams as string)?.split(',')[0] || dayjs().format('YYYY-MM-DD')
  );
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);

  const {
    data: availabilitySlot,
    isLoading: availabilityLoading,
    isFetching: availabilityFetching
  } = useGetAvailabilityAppointmentsByAppointmentTypeIdQuery(
    {
      accountId: accountId,
      appointmentTypeId: selectedAppointmentType?._id || '',
      from,
      to,
      type: BookingType.BOOKING,
      clinicianId
    },
    { skip: !accountId || !selectedAppointmentType?._id || !clinicianId }
  );

  const transformedTimeSlots = useMemo(() => {
    if (!availabilitySlot?.timeSlots) return [];
    return availabilitySlot.timeSlots.map(
      (slot) => convertTimeSlotTimeZone(slot, selectedClientTimeZone) // Apply client timezone conversion here
    );
  }, [availabilitySlot?.timeSlots, selectedClientTimeZone]);

  const invalidDates = useRef<Set<string>>(new Set<string>());
  const filteredData = useMemo(() => {
    const filterTimeSlotByDate = (selectedDate: string) => {
      return transformedTimeSlots.filter((timeSlotObj) => timeSlotObj.date === selectedDate);
    };
    invalidDates.current = new Set<string>(allDates);
    const map = new Map();
    if (!transformedTimeSlots) {
      return map;
    }
    transformedTimeSlots.forEach((item) => {
      if (!map.has(item.date)) {
        const values = getFilteredSlots(filterTimeSlotByDate(item.date), timeRangeFilter);
        map.set(item.date, values);
        if (values.length > 0) {
          invalidDates.current.delete(item.date);
        }
      }
    });
    return map;
  }, [timeRangeFilter, transformedTimeSlots]);

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.findIndex(
      (selectedSlot) =>
        selectedSlot.startDateTime === slot.startDateTime && selectedSlot.endDateTime === slot.endDateTime
    );
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlots, slot: val })) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  return (
    <div className={classNames(styles.container, 'custom-theme')}>
      <div className={styles.calendarWrapper}>
        <Datepicker
          calendarType={'month'}
          firstDay={1}
          pages={1}
          responsive={{
            custom: {
              breakpoint: 820,
              pages: 2
            }
          }}
          controls={['calendar']}
          renderCalendarHeader={() => (
            <div className={styles.customCalendarHeader}>
              <CalendarPrev />
              <CalendarNav />
              <CalendarNext />
            </div>
          )}
          showInput={false}
          isOpen
          min={minDate.format('YYYY-MM-DD')}
          max={maxDate.format('YYYY-MM-DD')}
          invalid={availabilityLoading ? Array.from(allDates) : [...Array.from(invalidDates.current)]}
          value={selectedDate}
          onChange={(e) => setSelectedDate(dayjs(e.value).format('YYYY-MM-DD'))}
          theme={'ios'}
          themeVariant={'light'}
          display="inline"
          showOuterDays={false}
        />
      </div>
      <div className={styles.slotWrapper}>
        <SlotPicker
          slots={filteredData.get(selectedDate)}
          selectedDate={selectedDate}
          selectedSlots={selectedTimeSlots}
          onClickSlot={onTimeSlotsChange}
          isLoading={availabilityLoading || availabilityFetching}
          remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
        />
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
