import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import { patientProfileServicesApiSlice } from './services/patientProfileServiceApisSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      // add services here
      checkInServicesApiSlice.middleware,
      clinicianProfileServicesApiSlice.middleware,
      scheduleServicesApiSlice.middleware,
      patientProfileServicesApiSlice.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
