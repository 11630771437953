import styles from './TimeSlot.module.scss';
import classnames from 'classnames';

interface TimeSlotProps {
  time: string;
  className?: string;
  active?: boolean;
}

const TimeSlot = ({ time, className, active }: TimeSlotProps) => {
  return <div className={classnames(styles.container, active && styles.active, className && className)}>{time}</div>;
};

export default TimeSlot;
