import styles from './PsychologistFAQ.module.scss';
import FAQBubble from './components/FAQBubble/FAQBubble';
import classnames from 'classnames';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import { brandConfig } from 'brand/config';
import CardWrapper from 'components/BaseComponent/CardWrapper/CardWrapper';

interface FAQProps {
  className?: string;
  id: string;
}

const PsychologistFAQ = ({ className, id }: FAQProps) => {
  return (
    brandConfig.faqConfig && (
      <div className={classnames(styles.container, className)} id={id}>
        <ContentLayout>
          <CardWrapper>
            <div className={styles.content}>
              <div className={styles.title}>{brandConfig.faqConfig.title}</div>
              <div className={styles.desc} dangerouslySetInnerHTML={{ __html: brandConfig.faqConfig.desc }} />
              <div className={styles.faqWrapper}>
                {brandConfig.faqConfig.faqData.map((obj, index) => (
                  <FAQBubble key={index} details={obj} />
                ))}
              </div>
            </div>
          </CardWrapper>
        </ContentLayout>
      </div>
    )
  );
};

export default PsychologistFAQ;
