import styles from './DeliveryMode.module.scss';
import {
  DELIVERY_TYPE_ICONS,
  DELIVERY_TYPE_ICONS_OFF,
  DELIVERY_TYPE_LABELS,
  DeliveryType
} from 'interfaces/Appointment';

type DeliveryModeProps = {
  deliveryOptions: DeliveryType[];
};

const DeliveryMode = ({ deliveryOptions }: DeliveryModeProps) => {
  const isActive = (type: DeliveryType | DeliveryType[]) =>
    Array.isArray(type) ? type.some((t) => deliveryOptions.includes(t)) : deliveryOptions.includes(type);

  const deliveryTypes = [
    { type: [DeliveryType.FaceToFace], label: DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace] },
    {
      type: [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient],
      label: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCall]
    },
    { type: [DeliveryType.VideoCall], label: DELIVERY_TYPE_LABELS[DeliveryType.VideoCall] },
    { type: [DeliveryType.Other], label: DELIVERY_TYPE_LABELS[DeliveryType.Other] }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.deliveryTypeOptions}>
        {deliveryTypes.map(({ type, label }) => (
          <div
            key={label}
            title={label}
            aria-label={label}
            className={`material-symbols-outlined ${
              isActive(type) ? styles.active : styles.inactive
            } ${styles.deliveryType}`}
          >
            {isActive(type) ? DELIVERY_TYPE_ICONS[type[0]] : DELIVERY_TYPE_ICONS_OFF[type[0]]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryMode;
