import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum SSTagTypes {
  AppointmentTypes = 'AppointmentTypes',
  AvailabilityAppointmentsByAppointmentTypeId = 'AvailabilityAppointmentsByAppointmentTypeId',
  ReservedAppointmentByIdRequest = 'ReservedAppointmentByIdRequest'
}

export const scheduleServicesApiSlice = createApi({
  reducerPath: 'scheduleServiceApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SCHED_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently().catch(() => '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(SSTagTypes),
  endpoints: () => ({})
});
