import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TIMEZONE_OPTIONS } from 'utils/constants/timezone';
import { RootState } from '../store';

const engageClientTimeZone = localStorage.getItem('engageTimeZoneLocalStorage');
const initialSelectedTimezone =
  engageClientTimeZone ||
  TIMEZONE_OPTIONS.find((tzObj) => tzObj.value === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.value ||
  TIMEZONE_OPTIONS[0].value;

interface TimeZoneState {
  selectedClientTimeZone: string;
}

const initialState: TimeZoneState = {
  selectedClientTimeZone: initialSelectedTimezone
};

const clientTimeZoneSlice = createSlice({
  name: 'clientTimezone',
  initialState,
  reducers: {
    setSelectedTimezone: (state, action: PayloadAction<TimeZoneState['selectedClientTimeZone']>) => {
      state.selectedClientTimeZone = action.payload;
      localStorage.setItem('engageTimeZoneLocalStorage', action.payload); // Update localStorage when state changes
    }
  }
});

export const clientTimeZone = (state: RootState) => state.clientTimeZoneSlice;

export const { setSelectedTimezone } = clientTimeZoneSlice.actions;

export default clientTimeZoneSlice.reducer;
