import { Skeleton } from 'antd';
import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SearchBar from './components/SearchBar/SearchBar';
import { ComponentType, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Tooltip } from 'react-tooltip';
import { scrollToView } from 'utils/scrollToView';
import styles from './DropdownSearchable.module.scss';
import OptionItem, { IOptionItem } from './OptionItem';
import { useGetSelectedOption } from './selectedOption';

export enum DropdownVariant {
  text = 'text',
  box = 'box'
}

interface DropdownSearchableProps {
  options: IOptionItem[];
  selected: string | string[] | undefined;
  onSelect?: (value: string, itemFlag?: string, menuValue?: string) => void;
  className?: string;
  controllerClassName?: string;
  controllerClassNameActive?: string;
  menuPlacement?: 'top' | 'bottom';
  menuVerticalPlacement?: 'left' | 'right';
  isActive?: boolean;
  placeholder?: string;
  rowsPerScroll?: number;
  rowsPerScrollPage?: number;
  isSplittedList?: boolean;
  mainOptions?: IOptionItem[];
  otherOptionsHeader?: string;
  noOtherItemFoundLabel?: string;
  disabled?: boolean;
  searchable?: boolean;
  isLoading?: boolean;
  hasMoreData?: boolean;
  isFetchingMore?: boolean;
  optionWrapperClassName?: string;
  itemIconClassName?: string;
  itemClassName?: string;
  firstOptionClassName?: string;
  searchText?: string;
  onActive?: () => void;
  loadMore?: () => void;
  setSearchText?: (value: string) => void;
  error?: string;
  hideErrorDesc?: boolean;
  hideActiveLine?: boolean;
  hideIconOnLabel?: boolean;
  variant?: DropdownVariant;
  DisplayLabelChildren?: ComponentType<any>;
  DropdownItemChildren?: ComponentType<any>;
  label?: string;
  dropdownIconClassName?: string;
  isMultiSelect?: boolean;
  showCheckBox?: boolean;
  labelClassName?: string;
  optionLabelClassName?: string;
  note?: ReactNode;
  showBorderBottom?: boolean;
  borderBottomClassName?: string;
  disabledColor?: boolean;
  noItemFoundLabel?: string;
  fullLabel?: boolean;
  labelWrapperClassName?: string;
  dropdownLabelClass?: string;
  preLabel?: ReactNode;
  menuListContainerClassName?: string;
  itemLabelClassName?: string;
  onClickOutside?: () => void;
}

const DropdownSearchable = ({
  options,
  selected,
  onSelect,
  className,
  controllerClassName,
  controllerClassNameActive,
  menuPlacement = 'bottom',
  menuVerticalPlacement = 'right',
  isActive: isActiveProp,
  placeholder,
  rowsPerScroll = 3,
  rowsPerScrollPage = 5,
  isSplittedList,
  mainOptions,
  otherOptionsHeader,
  noOtherItemFoundLabel,
  disabled,
  searchable,
  isLoading,
  hasMoreData,
  isFetchingMore,
  optionWrapperClassName,
  itemIconClassName,
  itemClassName,
  firstOptionClassName,
  onActive,
  loadMore,
  searchText,
  setSearchText,
  error,
  hideErrorDesc,
  hideActiveLine,
  hideIconOnLabel,
  DisplayLabelChildren,
  DropdownItemChildren,
  variant = DropdownVariant.text,
  label,
  dropdownIconClassName,
  isMultiSelect,
  showCheckBox,
  labelClassName,
  optionLabelClassName,
  note,
  showBorderBottom,
  borderBottomClassName,
  disabledColor,
  noItemFoundLabel,
  fullLabel,
  labelWrapperClassName,
  dropdownLabelClass,
  preLabel,
  menuListContainerClassName,
  itemLabelClassName,
  onClickOutside
}: DropdownSearchableProps) => {
  const { menu, subMenu, menuList } = useGetSelectedOption(options, selected, mainOptions);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [otherOptionsActive, setOtherOptionsActive] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const itemsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      scrollToView({ id: 'selectedOption', inlineCenter: false });
    }
  }, [isActive]);

  useEffect(() => {
    searchText !== undefined && setSearchValue(searchText);
  }, [searchText]);

  const handleClickOutSide = (event: any) => {
    if (containerRef.current?.contains(event.target) || itemsRef.current?.contains(event.target)) {
      return;
    }
    setOtherOptionsActive(false);
    setIsActive(false);
    onClickOutside?.();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    setIsActive(!!isActiveProp);
  }, [isActiveProp]);

  const otherOptions: IOptionItem[] = useMemo(
    () =>
      isSplittedList && mainOptions
        ? options.filter((item) => !mainOptions.some((mainItem) => mainItem.value === item.value))
        : [],
    [options, mainOptions, isSplittedList]
  );

  const onScrollClick = () => {
    itemsRef.current?.scrollBy({
      top: rowsPerScroll * (itemsRef.current.scrollHeight / (otherOptions.length || options.length)),
      behavior: 'smooth'
    });
  };

  const onSelectOption = (value: string, itemFlag?: string, menuValue?: string) => {
    onSelect && value !== selected && onSelect(value, itemFlag, menuValue);
    setOtherOptionsActive(false);
    !isMultiSelect && setIsActive(false);
  };

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    setSearchText && setSearchText(value);
  };

  const renderTooltips = (options: IOptionItem[]) => {
    return options
      .filter((item) => item.tooltip)
      .map((item, index) => (
        <Tooltip id={`tooltip-${item.value}`} className={styles.tooltip} key={index}>
          {item.tooltip}
        </Tooltip>
      ));
  };

  const renderOptions = (options: IOptionItem[], showInput: boolean, useOtherCustomLabel?: boolean) => {
    const optionsMatchedSearch =
      showInput && searchValue
        ? options.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
        : options;
    return optionsMatchedSearch.length > 0 ? (
      optionsMatchedSearch.map((item, index) => (
        <div
          className={classNames(item.selected && firstOptionClassName)}
          data-tooltip-id={`tooltip-${item.value}`}
          key={index}
        >
          <OptionItem
            id={
              selected === item.value ||
              (isMultiSelect && selected?.includes(item.value)) ||
              item?.subMenu?.some((subMenuObj) => subMenuObj.value === selected)
                ? 'selectedOption'
                : ''
            }
            key={index}
            item={item}
            isSelected={
              item.value === selected ||
              (isMultiSelect && selected?.includes(item.value)) ||
              item?.subMenu?.some((subMenuObj) => subMenuObj.value === selected || selected?.includes(subMenuObj.value))
            }
            selectedValue={selected}
            onSelect={(value) => onSelectOption(value, item.itemFlag, item.value)}
            iconClassName={itemIconClassName}
            className={classNames(variant === DropdownVariant.box && styles.optionItem, itemClassName)}
            isSelectedItem={item.selected}
            customItem={DropdownItemChildren && <DropdownItemChildren props={item} />}
            showCheckBox={showCheckBox}
            labelClassName={itemLabelClassName}
          />
        </div>
      ))
    ) : isFetchingMore ||
      (searchText && searchValue.length > 0 && searchText?.length > 0 && searchValue !== searchText) ? (
      <div className={styles.loading}>
        <LoadingCircle />
      </div>
    ) : searchValue.length > 0 && optionsMatchedSearch.length === 0 ? (
      <div className={styles.noItems}>
        <i className="material-icons-outlined">search_off</i>
        No matching items found
      </div>
    ) : (
      <div className={styles.noItems}>
        {(useOtherCustomLabel && noOtherItemFoundLabel) || noItemFoundLabel || 'No items found'}
      </div>
    );
  };

  const renderOptionLists = (
    options: IOptionItem[],
    showOptionOther: boolean,
    showSearchInput: boolean,
    showScroll: boolean,
    useOtherCustomLabel?: boolean
  ) => {
    return (
      <>
        {showSearchInput && (
          <div className={styles.searchBoxWrapper}>
            <SearchBar
              containerClassName={styles.searchBox}
              clearTextIcon
              searchValue={searchValue}
              setSearchValue={onSearchChange}
              shouldFocusToInput
            />
          </div>
        )}
        <div
          className={styles.items}
          ref={itemsRef}
          onScroll={(e) => {
            showScroll &&
              setIsScrolledToBottom(
                e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight) < 10
              );
          }}
        >
          {loadMore ? (
            <InfiniteScroll
              hasMore={hasMoreData}
              loader={
                searchValue !== '' && (isFetchingMore || searchValue !== searchText) ? undefined : (
                  <div key={-1} className={styles.listLoading}>
                    <LoadingCircle />
                  </div>
                )
              }
              loadMore={loadMore}
              pageStart={1}
              useWindow={false}
              initialLoad={false}
            >
              {renderOptions(options, !showOptionOther, useOtherCustomLabel)}
            </InfiniteScroll>
          ) : (
            renderOptions(options, !showOptionOther, useOtherCustomLabel)
          )}
        </div>
        {showScroll && !isScrolledToBottom && !isFetchingMore && (
          <div
            className={classNames(styles.scrollDownText, isScrolledToBottom && styles.scrolledToBottom)}
            onClick={onScrollClick}
          >
            <div className={styles.scroll}>
              Scroll
              <i className="material-icons-outlined">keyboard_double_arrow_down</i>
            </div>
          </div>
        )}
        {showOptionOther && (
          <div
            onMouseEnter={() => setOtherOptionsActive(true)}
            onMouseLeave={() => setOtherOptionsActive(false)}
            className={styles.otherOptionsWrapper}
          >
            <OptionItem
              item={{ value: 'other', label: otherOptionsHeader || 'Other', icon: 'chevron_right' }}
              onSelect={() => {
                setOtherOptionsActive(!otherOptionsActive);
              }}
              isSelected={otherOptionsActive}
            />
            {otherOptions && otherOptionsActive && (
              <div className={styles.otherOptions}>
                {renderOptionLists(otherOptions, false, true, otherOptions.length > rowsPerScrollPage, true)}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(styles.container, variant === DropdownVariant.box && styles.boxContainer, className)}
      ref={containerRef}
    >
      {label && <span className={classNames(styles.dropdownLabel, dropdownLabelClass)}>{label}</span>}
      <button
        type="button"
        disabled={disabled}
        className={classNames(
          styles.dropdownController,
          variant === DropdownVariant.box && styles.boxDropdownController,
          disabled && styles.disabled,
          disabledColor && disabled && styles.disabledColor,
          error && styles.fieldError,
          controllerClassName,
          isActive && controllerClassNameActive
        )}
        onClick={() => {
          setIsActive(!isActive);
          onActive && onActive();
        }}
      >
        {preLabel}
        <div className={classNames(styles.optionLabel, optionLabelClassName)}>
          {DisplayLabelChildren && isMultiSelect && menuList.length > 0 ? (
            <div className={classNames(styles.menuListContainer, menuListContainerClassName)}>
              {menuList.map((menuObj, index) => (
                <DisplayLabelChildren key={index} props={menuObj} />
              ))}
            </div>
          ) : DisplayLabelChildren && menu.value ? (
            <DisplayLabelChildren props={menu} />
          ) : (
            <div className={classNames(styles.labelWrapper, labelWrapperClassName)}>
              <div
                className={classNames(
                  styles.label,
                  subMenu?.label && styles.subLabelContainer,
                  labelClassName,
                  fullLabel && styles.fullLabel
                )}
              >
                {selected && isLoading ? (
                  <div className={styles.skeletonLoading}>
                    <Skeleton.Input active />
                  </div>
                ) : isMultiSelect ? (
                  menuList.map((menuObj) => menuObj.label).join(', ') || placeholder
                ) : (
                  `${menu?.label || placeholder || ''}${menu?.subLabel ? ` ${menu.subLabel}` : ''}${
                    subMenu?.label ? ` - ${subMenu?.label}` : ''
                  }`
                )}
              </div>
              {menu?.icon && !hideIconOnLabel && (
                <i
                  className={classNames(
                    menu.iconVariant ?? 'material-icons',
                    styles.icon,
                    itemIconClassName,
                    menu.iconClass
                  )}
                >
                  {menu.icon}
                </i>
              )}
            </div>
          )}
        </div>
        {!disabled && (
          <i
            className={classNames(
              'material-icons',
              styles.icon,
              styles.dropdownIcon,
              dropdownIconClassName,
              error && styles.iconError
            )}
          >
            arrow_drop_down
          </i>
        )}
        {(showBorderBottom || (isActive && !hideActiveLine && !error)) && (
          <div className={classNames(styles.borderBottom, isActive && styles.active, borderBottomClassName)} />
        )}
      </button>
      {isActive && (
        <div>
          <div
            id="renderOptionLists"
            className={classNames(
              styles.optionsWrapper,
              variant === DropdownVariant.box && styles.boxOptionsWrapper,
              styles[menuPlacement],
              styles[menuVerticalPlacement],
              optionWrapperClassName
            )}
          >
            {isLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <>
                {isSplittedList && mainOptions ? (
                  <div className={styles.mainOptions}>{renderOptionLists(mainOptions, true, false, false)}</div>
                ) : (
                  <>
                    <div className={styles.options}>
                      {renderOptionLists(options, false, !!searchable, options.length > rowsPerScrollPage)}
                      {note}
                    </div>
                  </>
                )}
              </>
            )}
            {renderTooltips(options)}
          </div>
        </div>
      )}
      <>{error && !hideErrorDesc && <div className={classNames(styles.fieldError, styles.errorLabel)}>{error}</div>}</>
    </div>
  );
};

export default DropdownSearchable;
