import styles from './PsychologistDetail.module.scss';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import CardWrapper from 'components/BaseComponent/CardWrapper/CardWrapper';
import EngageFooter from 'components/EngageComponent/EngageFooter/EngageFooter';
import { useGetAccountClinicianDetailsQuery } from 'reduxToolkit/endpoints/clinicianProfileService/accounts';
import { brandConfig } from 'brand/config';
import { useParams } from 'react-router-dom';
import { PRONOUN_OPTIONS } from 'components/EngageComponent/PsychologistCard/PsychologistCard';
import { MEDICARE_ROLES_OPTIONS, MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'interfaces/Clinician/enums';
import { FIT_DELIVERY_LIST_LABELS, FitDeliveryList } from 'interfaces/Engage/fitFilter';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';
import PsychologistFAQ from './components/PsychologistFAQ/PsychologistFAQ';
import { useEffect, useRef, useState } from 'react';
import TimeSlot from 'components/TimeSlot/TimeSlot';
import { useGetAppointmentTypesQuery } from 'reduxToolkit/endpoints/scheduleServices/appointmentType';
import PsychologistAppointment from './components/PsychologistAppointment/PsychologistAppointment';
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button/Button';

const PsychologistDetail = () => {
  const fixedCardRef = useRef<HTMLDivElement>(null);
  const faqWrapperRef = useRef<HTMLDivElement>(null);
  const [activeAnchor, setActiveAnchor] = useState('introSection');

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1100) {
        return;
      }

      const fixedCard = fixedCardRef.current;
      const faqWrapper = faqWrapperRef.current;

      if (fixedCard && faqWrapper) {
        const faqRect = faqWrapper.getBoundingClientRect();
        const fixedCardHeight = fixedCard.offsetHeight + 190;

        if (faqRect.top <= fixedCardHeight) {
          fixedCard.style.position = 'absolute';
          fixedCard.style.top = `${faqWrapper.offsetTop - fixedCardHeight + 130}px`;
        } else {
          fixedCard.style.position = 'fixed';
          fixedCard.style.top = '130px';
        }
      }
    };

    const handleResize = () => {
      // Directly handle position reset for small screens
      const fixedCard = fixedCardRef.current;
      if (window.innerWidth <= 1100 && fixedCard) {
        fixedCard.style.position = 'initial';
        fixedCard.style.top = '0';
      }

      // Always execute the scroll handler when screen width > 1100
      if (window.innerWidth > 1100) {
        handleScroll();
      }
    };

    // Initial setup: ensure scroll handling starts correctly
    handleScroll();

    // Attach scroll and resize listeners
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const sections = [
      { id: 'introSection' },
      { id: 'appointmentSection' },
      { id: 'specialisationSection' },
      { id: 'serviceOfferSection' }
    ];
    const sectionElements = sections.map((section) => document.getElementById(section.id));

    const fixedCardElement = fixedCardRef.current;

    // Function to calculate if section's top reaches the fixedCard
    const checkIntersection = () => {
      if (!fixedCardElement) return;

      // Get the position of the fixed card (top)
      const fixedCardTop = fixedCardElement.getBoundingClientRect().top;

      sectionElements.forEach((element) => {
        if (element) {
          const sectionTop = element.getBoundingClientRect().top;

          // If the top of the section reaches or crosses the fixed card's top, highlight the anchor
          if (sectionTop <= fixedCardTop) {
            setActiveAnchor(element.id); // Highlight the anchor link when the top of section hits the fixed card
          }
        }
      });
    };

    // Check on scroll or when component mounts
    window.addEventListener('scroll', checkIntersection);
    checkIntersection(); // initial check when the page loads

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', checkIntersection);
    };
  }, []);

  const { clinicianId } = useParams<{ clinicianId: string }>();
  const {
    data: clinicianDetails,
    isLoading: clinicianDetailsLoading
    // isFetching: clinicianDetailsFetching
  } = useGetAccountClinicianDetailsQuery({
    slugUrl: brandConfig.slugUrl,
    clinicianId: clinicianId || ''
  });

  const { data: appointmentTypes } = useGetAppointmentTypesQuery(
    {
      accountId: clinicianDetails?.accountId || '',
      clinicianId: clinicianDetails?._id,
      excludeAllAssignmentMode: '1',
      excludeHideFromListing: '1'
      // claimType: clientAppointmentType
    },
    { skip: !clinicianDetails }
  );

  const anchorTo = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    const fixedCardElement = fixedCardRef.current;

    if (element && fixedCardElement) {
      // Get the top position of the section and adjust it based on the fixed card's position
      const sectionTop = element.getBoundingClientRect().top + window.scrollY; // Absolute position of the section
      const fixedCardHeight = fixedCardElement.offsetHeight;

      // Scroll to the section, offset by the height of the fixed card
      window.scrollTo({
        top: sectionTop - fixedCardHeight + 228, // Adjusted to stop at the same line as the fixed card
        behavior: 'smooth'
      });
    }
  };

  return (
    <HelmetWrapper title={'Psychologist Details'}>
      <div className={styles.container}>
        <ContentLayout className={styles.containerBox}>
          <ClientHeader withMenu />
          <CardWrapper>
            <div className={styles.content}>
              <div className={styles.fixCardWrapper}>
                <div className={styles.followCard} ref={fixedCardRef}>
                  <div className={styles.clinicianInfoWrapper}>
                    <div className={styles.avatarWrapper}>
                      <img alt={'clinician profile'} src={clinicianDetails?.avatar} className={styles.avatarImg} />
                    </div>
                    <div className={styles.nameWrapper}>
                      <div className={styles.name}>{clinicianDetails?.name}</div>
                      <div className={styles.roleSection}>
                        {clinicianDetails?.pronouns
                          ? `${PRONOUN_OPTIONS[clinicianDetails?.pronouns as keyof typeof PRONOUN_OPTIONS]}`
                          : ''}
                        {clinicianDetails?.medicare?.role ? (
                          <>
                            <div className={styles.divider}>|</div>
                            <div className={styles.roleBox}>
                              {
                                MEDICARE_ROLES_OPTIONS[
                                  clinicianDetails.medicare.role as keyof typeof MEDICARE_ROLES_OPTIONS
                                ]
                              }
                              <span className={`material-icons-outlined ${styles.verifiedIcon}`}>verified</span>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.nextAvailableSection}>
                    <div className={styles.nextLabel}>Next Available</div>
                    <div className={styles.timeSlotCard}>
                      <div className={styles.nextAvailableCard}>
                        <div className={styles.dateWrapper}>
                          <div className={styles.date}>
                            15 <span className={styles.month}>Nov</span>
                          </div>
                          <div className={styles.day}>Wednesday</div>
                        </div>
                        <div className={styles.timeslotWrapper}>
                          <TimeSlot time={'09:00 AM'} />
                          <TimeSlot time={'10:00 AM'} />
                          <TimeSlot time={'11:00 AM'} />
                          <TimeSlot time={'12:00 PM'} />
                          <TimeSlot time={'01:00 PM'} />
                          <TimeSlot time={'02:00 PM'} />
                        </div>
                      </div>
                      <div className={styles.nextAvailableCard}>
                        <div className={styles.dateWrapper}>
                          <div className={styles.date}>
                            16 <span className={styles.month}>Nov</span>
                          </div>
                          <div className={styles.day}>Wednesday</div>
                        </div>
                        <div className={styles.timeslotWrapper}>
                          <TimeSlot time={'09:00 AM'} />
                          <TimeSlot time={'10:00 AM'} />
                          <TimeSlot time={'11:00 AM'} />
                          <TimeSlot time={'12:00 PM'} />
                          <TimeSlot time={'01:00 PM'} />
                          <TimeSlot time={'02:00 PM'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.anchorSection}>
                    <div
                      className={activeAnchor === 'introSection' ? styles.anchorLinkActive : styles.anchorLink}
                      onClick={() => anchorTo('introSection')}
                    >
                      Meet {clinicianDetails?.name}
                    </div>
                    <div
                      className={activeAnchor === 'appointmentSection' ? styles.anchorLinkActive : styles.anchorLink}
                      onClick={() => anchorTo('appointmentSection')}
                    >
                      BOOK AN APPOINTMENT
                    </div>
                    <div
                      className={activeAnchor === 'specialisationSection' ? styles.anchorLinkActive : styles.anchorLink}
                      onClick={() => anchorTo('specialisationSection')}
                    >
                      SPECIALISATIONS
                    </div>
                    <div
                      className={activeAnchor === 'serviceOfferSection' ? styles.anchorLinkActive : styles.anchorLink}
                      onClick={() => anchorTo('serviceOfferSection')}
                    >
                      OTHERS
                    </div>
                  </div>
                </div>
              </div>
              {clinicianDetailsLoading ? (
                <div>loading</div>
              ) : (
                <div className={styles.detailsWrapper}>
                  <div id={'introSection'} className={styles.infoCard}>
                    <div className={styles.bigTitle}>Meet {clinicianDetails?.name}</div>
                    <div className={styles.desc}>{clinicianDetails?.helmControl.shortDescription}</div>
                    <div className={styles.secondaryTitle}>EXPERIENCE & CREDENTIALS</div>
                    <div className={styles.desc}>{clinicianDetails?.helmControl.credentials}</div>
                  </div>
                  <div id={'appointmentSection'} className={styles.infoCard}>
                    <PsychologistAppointment
                      accountId={clinicianDetails?.accountId || ''}
                      clinicianId={clinicianDetails?._id || ''}
                      processNextAvailability={clinicianDetails?.nextAvailabilities}
                    />
                  </div>
                  <div id={'specialisationSection'} className={styles.infoCard}>
                    <div className={styles.title}>Area of Practice</div>
                    {clinicianDetails?.helmControl.primarySpecialisations &&
                      clinicianDetails?.helmControl.primarySpecialisations.length > 0 && (
                        <div>
                          <div className={styles.secondaryTitle}>MAIN AREAS OF PRACTICE</div>
                          <div className={styles.specialisationWrapper}>
                            {clinicianDetails?.helmControl.primarySpecialisations.map((specialisationObj, index) => (
                              <div key={index} className={styles.specialisation}>
                                {MENTAL_HEALTH_LIST_LABELS[specialisationObj as MentalHealthList]}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {clinicianDetails?.helmControl.secondarySpecialisations &&
                      clinicianDetails?.helmControl.secondarySpecialisations.length > 0 && (
                        <div>
                          <div className={styles.secondaryTitle}>OTHER SPECIALISATIONS</div>
                          <div className={styles.specialisationWrapper}>
                            {clinicianDetails?.helmControl.secondarySpecialisations.map((specialisationObj, index) => (
                              <div key={index} className={styles.specialisation}>
                                {MENTAL_HEALTH_LIST_LABELS[specialisationObj as MentalHealthList]}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {clinicianDetails?.helmControl.styles && clinicianDetails?.helmControl.styles.length > 0 && (
                      <div>
                        <div className={styles.secondaryTitle}>Trained in</div>
                        <div className={styles.specialisationWrapper}>
                          {clinicianDetails?.helmControl.styles.map((styleObj, index) => (
                            <div key={index} className={styles.specialisation}>
                              {styleObj}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div>
                      <div className={styles.secondaryTitle}>Qualifications</div>
                      {clinicianDetails?.helmControl.bonaFides?.qualifications?.active &&
                        clinicianDetails?.helmControl.bonaFides?.qualifications.value?.map(
                          (qualificationObj, index) => (
                            <div key={index} className={styles.qualificationsWrapper}>
                              <div className={`material-symbols-outlined ${styles.graduateIcon}`}>school</div>
                              <div>{qualificationObj}</div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  <div id={'serviceOfferSection'} className={styles.infoCard}>
                    <div className={styles.title}>Services Offered</div>
                    <div>
                      <div className={styles.secondaryTitle}>{clinicianDetails?.name.toUpperCase()} DELIVERS</div>
                      <div className={styles.verticalWrapper}>
                        {clinicianDetails?.helmControl.deliveryModes && (
                          <div className={styles.specialisationWrapper}>
                            {clinicianDetails?.helmControl?.deliveryModes?.map((deliveryMode, index) => (
                              <div key={index} className={styles.specialisation}>
                                {deliveryMode === FitDeliveryList.video && (
                                  <i className={`material-symbols-outlined ${styles.deliveryModeIcon}`}>videocam</i>
                                )}
                                {deliveryMode === FitDeliveryList.phone && (
                                  <i className={`material-symbols-outlined ${styles.deliveryModeIcon}`}>call</i>
                                )}
                                {FIT_DELIVERY_LIST_LABELS[deliveryMode as keyof typeof FitDeliveryList]}
                              </div>
                            ))}
                          </div>
                        )}
                        {clinicianDetails?.helmControl?.bonaFides?.languages?.active &&
                          clinicianDetails?.helmControl?.bonaFides?.languages?.value && (
                            <>
                              {clinicianDetails?.helmControl.deliveryModes && <>in</>}
                              <div className={styles.specialisationWrapper}>
                                {clinicianDetails.helmControl.bonaFides.languages.value.map((languageObj, index) => (
                                  <div key={index} className={styles.specialisation}>
                                    {LANGUAGE_COLLECTION[languageObj as keyof typeof LANGUAGE_COLLECTION]}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    {clinicianDetails?.helmControl.fundingMethods &&
                      clinicianDetails?.helmControl.fundingMethods.length > 0 && (
                        <div>
                          <div className={styles.secondaryTitle}>FUNDING MODES ACCEPTED</div>
                          <div className={styles.fundingModeWrapper}>
                            {clinicianDetails?.helmControl.fundingMethods.map((fundingMethodObj, index) => (
                              <div key={index} className={styles.specialisation}>
                                <i className={`material-symbols-outlined ${styles.fundingIcon}`}>credit_card</i>
                                {fundingMethodObj}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {appointmentTypes && appointmentTypes?.length > 0 && (
                      <div>
                        <div className={styles.secondaryTitle}>APPOINTMENTS OFFERED</div>
                        <div className={styles.appointmentOfferWrapper}>
                          {appointmentTypes?.map((appointmentOfferObj, index) => (
                            <div key={index} className={styles.appointmentOffer}>
                              <i className={`material-symbols-outlined ${styles.fundingIcon}`}>calendar_today</i>
                              {appointmentOfferObj.name}
                              <Button
                                className={styles.appointmentOfferBtn}
                                variant={ButtonVariant.Outlined}
                                size={ButtonSize.Small}
                              >
                                View available dates
                                <i className={`material-symbols-outlined ${styles.arrowIcon}`}>arrow_forward_ios</i>
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </CardWrapper>
        </ContentLayout>
        <div ref={faqWrapperRef}>
          <PsychologistFAQ id={'faq'} />
        </div>
        <div className={styles.footerWrapper}>
          <EngageFooter />
        </div>
        <TacklitFooter darkFooter />
      </div>
    </HelmetWrapper>
  );
};

export default PsychologistDetail;
