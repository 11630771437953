import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { brandConfig } from 'brand/config';
import { security } from 'utils/security';
import { retrieveUserSession } from 'utils/userSession';

export enum CPSTagTypes {
  AccountPracticeInfo = 'AccountPracticeInfo',
  AttachedClinicianDetails = 'AttachedClinicianDetails',
  ClientRecordsMe = 'ClientRecordsMe',
  ClientRecordTasks = 'ClientRecordTasks',
  ClinicianList = 'ClinicianList',
  ClinicianDetails = 'ClinicianDetails'
}

export const clinicianProfileServicesApiSlice = createApi({
  reducerPath: 'clinicianProfileServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token =
        (await security.getAccessTokenSilently().catch(() => '')) ||
        retrieveUserSession(brandConfig.userSessionKey).authToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(CPSTagTypes),
  endpoints: () => ({})
});
