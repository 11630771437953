import { isDST } from '../dayjsExtended';
import { brandConfig } from 'brand/config';

const AU_TIMEZONE_LABEL: Record<
  string,
  {
    dstCode: string;
    nonDstCode: string;
    desc: string;
    shortDesc: string;
    nonDstHr: string;
    dstHr: string;
    label: string;
  }
> = {
  'Australia/Sydney': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne, Canberra, & Tasmania',
    shortDesc: 'NSW, VIC, ACT, & Tasmania',
    nonDstHr: '10',
    dstHr: '11',
    label: 'New South Wales/Sydney'
  },
  'Australia/Melbourne': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne, Canberra, & Tasmania',
    shortDesc: 'NSW, VIC, ACT, & Tasmania',
    nonDstHr: '10',
    dstHr: '11',
    label: 'Victoria/Melbourne'
  },
  'Australia/Brisbane': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  'Australia/Queensland': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  'Australia/Adelaide': {
    nonDstCode: 'ACST',
    dstCode: 'ACDT',
    desc: 'South Australia/Adelaide',
    shortDesc: 'South Australia',
    nonDstHr: '9.5',
    dstHr: '10.5',
    label: 'South Australia/Adelaide'
  },
  'Australia/Darwin': {
    nonDstCode: 'ACST',
    dstCode: 'ACST',
    desc: 'Northern Territory/Darwin',
    shortDesc: 'Northern Territory',
    nonDstHr: '9.5',
    dstHr: '9.5',
    label: 'Northern Territory/Darwin'
  },
  'Australia/Perth': {
    nonDstCode: 'AWST',
    dstCode: 'AWST',
    desc: 'Western Australia/Perth',
    shortDesc: 'Western Australia',
    nonDstHr: '8',
    dstHr: '8',
    label: 'Western Australia/Perth'
  },
  'Pacific/Norfolk': {
    nonDstCode: 'NFT',
    dstCode: 'NFDT',
    desc: 'Norfolk Island/Kingston',
    shortDesc: 'Norfolk Island',
    nonDstHr: '11',
    dstHr: '12',
    label: 'Norfolk Island/Kingston'
  },
  'Indian/Christmas': {
    nonDstCode: 'CXT',
    dstCode: 'CXT',
    desc: 'Christmas Island',
    shortDesc: 'Christmas Island',
    nonDstHr: '7',
    dstHr: '7',
    label: 'Christmas Island'
  },
  'Indian/Cocos': {
    nonDstCode: 'CCT',
    dstCode: 'CCT',
    desc: 'Cocos Islands',
    shortDesc: 'Cocos Islands',
    nonDstHr: '6.5',
    dstHr: '6.5',
    label: 'Cocos Islands'
  },
  'Pacific/Auckland': {
    nonDstCode: 'NZST',
    dstCode: 'NZDT',
    desc: 'New Zealand',
    shortDesc: 'Auckland',
    nonDstHr: '12',
    dstHr: '13',
    label: 'New Zealand'
  }
};

const generateTimeZoneLabel = (timezone: string) => {
  const getTimeZoneLabel = AU_TIMEZONE_LABEL[timezone];

  const isDSTDate = isDST(timezone);
  const dstCode = isDSTDate ? getTimeZoneLabel.dstCode : getTimeZoneLabel.nonDstCode;
  const dstHr = isDSTDate ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;
  return {
    fullLabel: `${getTimeZoneLabel.label} (${dstCode}) +${dstHr}`,
    shortLabel: `(${dstCode}) ${getTimeZoneLabel.shortDesc}`,
    dstCode,
    dstHr,
    label: getTimeZoneLabel.label
  };
};

export const TIMEZONE_OPTIONS = Object.keys(AU_TIMEZONE_LABEL).map((timezone) => ({
  value: timezone,
  ...generateTimeZoneLabel(timezone as keyof typeof AU_TIMEZONE_LABEL)
}));

export const engageTimeZoneLocalStorage = `ENGAGE-${brandConfig.brand.toUpperCase()}-CLIENT-TIMEZONE`;
export const defaultClinicianTimezone = brandConfig.defaultTimezone;
