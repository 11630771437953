import { CountryISO } from 'interfaces/countryInterface';
import { tacklitConfig } from './tacklit/config';
import { cawConfig } from './caw/config';
import { InfoFieldType, PaymentOption } from 'interfaces/signupConfigInterface';
import { configAU } from './configAU';
import { configUK } from './configUK';
import { configNZ } from './configNZ';
import { configSA } from './configSA';

export interface CountryConfigInterface {
  countryCode: string;
  moneyIcon: string;
  currencySymbol: string;
  currencyCode: string;
  taxLabel: string;
  taxRateLabel: string;
  codeLabel: string;
  mobileCountryCode: string;
  defaultTimezone: string;
  contactMaskNumber: string;
  contactMaskNumberWithX: string;
  emergencyNumber: string;
  lifeLineNumber: string;
  practiceSettings: {
    registrationNumberLabel: string;
  };
}

export interface BrandConfig {
  brand: string;
  accountId: string;
  slugUrl: string;
  favIcon: string;
  defaultTimezone: string;
  metaInfo: {
    title: string;
    desc: string;
  };
  logo: {
    darkLogo: string;
    whiteLogo: string;
  };
  tracking: {
    gtmId: string;
    ga4Id: string;
  };
  region: CountryISO;
  link: {
    contactUsText: string;
    contactUsUrl: string;
  };
  contactLink: {
    phoneNumber: string;
    email: string;
    address: string;
  };
  pageConfig: {
    headerMenu: {
      allowEditStripeCard: boolean;
      useDarkLogo: boolean;
      allowBookNewAppointment: boolean;
    };
    engageFooter: {
      useDarkLogo: boolean;
      footerDescription: string;
    };
  };
  faqConfig?: {
    title: string;
    desc: string;
    faqData: {
      title: string;
      desc: string;
    }[];
  };
  engageConfig: {
    timer: number;
    createAsRecordOnly: boolean;
    paymentMethod: {
      paymentOption: PaymentOption;
    };
    infoFields: InfoFieldType[]; // Array of InfoFieldType keys, so can control the reorder
    welcomeMessage: string;
  };
}

interface brandObject {
  [key: string]: BrandConfig;
}

interface countryObject {
  [key: string]: CountryConfigInterface;
}

export const BRAND = process.env.REACT_APP_BRAND || 'tacklit';

const configMapping: brandObject = {
  tacklit: tacklitConfig,
  caw: cawConfig
};

const countryConfigMapping: countryObject = {
  gb: configUK,
  au: configAU,
  nz: configNZ,
  sa: configSA
};

export const brandConfig = {
  ...configMapping[BRAND],
  countryConfig: countryConfigMapping[configMapping[BRAND].region],
  userSessionKey: `${BRAND}UserSession`
};
