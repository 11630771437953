import styles from './PsychologistListing.module.scss';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import PsychologistCard from 'components/EngageComponent/PsychologistCard/PsychologistCard';
import CardWrapper from 'components/BaseComponent/CardWrapper/CardWrapper';
import EngageFooter from 'components/EngageComponent/EngageFooter/EngageFooter';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import {
  ClinicianListParam,
  useGetAccountClinicianListQuery
} from 'reduxToolkit/endpoints/clinicianProfileService/accounts';
import ListingFilter from 'components/EngageComponent/ListingFilter/ListingFilter';
import { AgeRangeList, GenderList, MentalHealthList } from 'interfaces/Clinician/enums';
import { listInterface } from 'components/EngageComponent/FilterDropdown/FilterDropdown';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { listingViewType, PsychologistFitFilter } from 'interfaces/Engage/fitFilter';
import queryString from 'query-string';
import { ENGAGE_ROUTES_LIST } from 'routes/constants';
import FitFilter from 'components/EngageComponent/FitFilter/FitFilter';
import { getDaysFromQuery, sortWeekDays } from './utils/getDaysFromQuery';
import TimeZoneDropdown from 'components/EngageComponent/TimeZoneDropdown/TimeZoneDropdown';
import { brandConfig } from 'brand/config';
import { getFitTimeFilter } from './utils/getFitTimeFilter';
import InfiniteScroll from 'react-infinite-scroller';
import { Skeleton } from 'antd';
import { clientTimeZone } from 'reduxToolkit/slice/clientTimeZoneSlice';
import { useAppSelector } from 'reduxToolkit/hooks';

const { PRACTITIONER } = ENGAGE_ROUTES_LIST;

const LIMIT_PER_PAGE = 20;

const PsychologistListing = () => {
  const navigate = useNavigate();
  const { filter } = useParams<{ filter: string }>();
  const getPathFilter = filter?.split('-') || [];
  const location = useLocation();
  const queryParam = location.search;
  const { selectedClientTimeZone } = useAppSelector(clientTimeZone);

  const getMentalConcernFilter: MentalHealthList[] = Object.values(MentalHealthList).filter((obj) =>
    getPathFilter.includes(obj)
  ) as MentalHealthList[];
  const getAgeRangeFilter: AgeRangeList = Object.keys(AgeRangeList).filter((obj) =>
    getPathFilter.includes(obj)
  )[0] as AgeRangeList;
  const getGenderFilter: GenderList = Object.keys(GenderList).filter((obj) =>
    getPathFilter.includes(obj)
  )[0] as GenderList;

  const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] =
    useState<MentalHealthList[]>(getMentalConcernFilter);
  const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRangeList | undefined>(getAgeRangeFilter);
  const [selectedGender, setSelectedGender] = useState<GenderList | undefined>(getGenderFilter);

  const [clinicianListCurrentPage, setClinicianListCurrentPage] = useState<number>(1);

  const { gender, days, time, deliveryMode, language, style, view }: PsychologistFitFilter =
    queryString.parse(queryParam);

  const [selectedFitFilter, setSelectedFitFilter] = useState<PsychologistFitFilter>({
    gender: gender || undefined,
    days: getDaysFromQuery(days) || undefined,
    time: time || undefined,
    deliveryMode: deliveryMode || undefined,
    language: language || undefined,
    style: style || undefined,
    view: view || listingViewType.gridView
  });

  const filterQuery: ClinicianListParam = useMemo(
    () => ({
      ...selectedFitFilter,
      ...(selectedMentalHealthConcern.length > 0 && {
        clientMentalHealthConcern: selectedMentalHealthConcern.join(',')
      }),
      clientAgeRange: selectedAgeRange,
      days: selectedFitFilter.days,
      deliveryMode: selectedFitFilter.deliveryMode,
      ...(selectedGender !== GenderList.genderNotListed && { clientGender: selectedGender }),
      ...getFitTimeFilter({ time: selectedFitFilter.time, timezone: selectedClientTimeZone }),
      // isNewClient,
      page: clinicianListCurrentPage,
      perPage: LIMIT_PER_PAGE
    }),
    [
      selectedAgeRange,
      selectedFitFilter,
      selectedGender,
      selectedMentalHealthConcern,
      selectedClientTimeZone,
      clinicianListCurrentPage
      // isNewClient
    ]
  );

  const {
    data: clinicianList,
    isLoading: clinicianListLoading,
    isFetching: clinicianListFetching
  } = useGetAccountClinicianListQuery({
    slugUrl: brandConfig.slugUrl,
    clientTimeZone: selectedClientTimeZone,
    params: filterQuery,
    infiniteLoad: true
  });

  const handleNavigatePath = (value: string[], queryParamValue?: PsychologistFitFilter) => {
    const generateFilterPath = value.filter((obj) => obj !== undefined).join('-');
    const newPath = generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
      filter: generateFilterPath
    });
    const queryParam = queryParamValue || selectedFitFilter;
    const sortedQueryParam = {
      ...queryParam,
      days: queryParam.days?.length ? sortWeekDays(queryParam.days) : undefined
    };
    const generateQueryParam = queryString.stringify(sortedQueryParam, {
      sort: false,
      arrayFormat: 'comma'
    });
    navigate(`${newPath}${generateQueryParam ? `?${generateQueryParam}` : ''}`);
  };

  const handleMentalHealthChange = (value: listInterface) => {
    const checkIfDuplicate = selectedMentalHealthConcern.some((mhObj) => mhObj === value.value);
    setClinicianListCurrentPage(1);
    const newCollection: MentalHealthList[] = checkIfDuplicate
      ? selectedMentalHealthConcern.filter((mhObj) => mhObj !== value.value)
      : [...selectedMentalHealthConcern, value.value as MentalHealthList];

    setSelectedMentalHealthConcern(() => newCollection);
    const newPathCollection = [...newCollection, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection);
  };

  const handleAgeRangeChange = (value: listInterface | undefined) => {
    setClinicianListCurrentPage(1);
    const newAgeRangeValue = value?.value as AgeRangeList;
    setSelectedAgeRange(newAgeRangeValue);
    const newPathCollection = [...selectedMentalHealthConcern, newAgeRangeValue, selectedGender] as string[];
    handleNavigatePath(newPathCollection);
  };

  const handleResetValue = () => {
    setClinicianListCurrentPage(1);
    setSelectedMentalHealthConcern([]);
    setSelectedAgeRange(undefined);
    setSelectedGender(undefined);
    handleNavigatePath([]);
  };

  const handleChangeFit = (fitValue: PsychologistFitFilter) => {
    setClinicianListCurrentPage(1);
    setSelectedFitFilter(fitValue);
    const newPathCollection = [...selectedMentalHealthConcern, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection, fitValue);
  };

  const handleFitResetValue = () => {
    setClinicianListCurrentPage(1);
    const defaultValue = {
      gender: undefined,
      days: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    const newPathCollection = [...selectedMentalHealthConcern, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection, defaultValue);
  };

  const hasMorePsychologists = useMemo(
    () => (clinicianList?.clinicians.length || 0) < (clinicianList?.paging.totalItem || 0),
    [clinicianList]
  );

  const totalEpisodeListPage = clinicianList?.paging ? Math.ceil(clinicianList.paging.totalItem / LIMIT_PER_PAGE) : 1;

  const loadMoreEpisodeItem = useCallback(() => {
    const page = clinicianList?.paging.page || 1;
    if (!clinicianListFetching && page < totalEpisodeListPage) {
      setClinicianListCurrentPage(page + 1);
    }
  }, [totalEpisodeListPage, clinicianListFetching, clinicianList]);

  return (
    <HelmetWrapper title={'Psychologist Listing'}>
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <ContentLayout>
            <ClientHeader withMenu />
            <CardWrapper className={styles.filterWrapper}>
              <ListingFilter
                selectedMentalHealthConcern={selectedMentalHealthConcern}
                onChangeMentalHealthConcern={handleMentalHealthChange}
                selectedAgeRange={selectedAgeRange}
                onChangeAgeRange={handleAgeRangeChange}
                handleResetValue={handleResetValue}
                isLoading={clinicianListLoading}
              />
              <div className={styles.timezone}>
                <TimeZoneDropdown />
              </div>
            </CardWrapper>
          </ContentLayout>
        </div>
        <div className={styles.filterFitWrapper}>
          <ContentLayout>
            <CardWrapper>
              <FitFilter
                selectedFitValue={selectedFitFilter}
                onChangeFitValue={handleChangeFit}
                handleFitResetValue={handleFitResetValue}
                isLoading={clinicianListLoading}
              />
            </CardWrapper>
          </ContentLayout>
        </div>
        <ContentLayout className={styles.content}>
          <CardWrapper>
            <div>
              {clinicianListLoading || (clinicianListFetching && clinicianListCurrentPage === 1) ? (
                <div
                  className={classNames(
                    styles.psychologistListWrapper,
                    selectedFitFilter.view === listingViewType.listView ? styles.listView : styles.gridView
                  )}
                >
                  {[...Array(9)].map((_, i) => (
                    <div key={i} className={styles.loadingWrapper}>
                      <div className={styles.loadingDesc}>
                        <Skeleton active />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <InfiniteScroll
                  className={classNames(
                    styles.psychologistListWrapper,
                    selectedFitFilter.view === listingViewType.listView ? styles.listView : styles.gridView
                  )}
                  loadMore={loadMoreEpisodeItem}
                  initialLoad={false}
                  pageStart={0}
                  hasMore={hasMorePsychologists}
                  loader={
                    <div className={styles.loadingWrapper} key="loading">
                      <div className={styles.loadingDesc}>
                        <Skeleton active />
                      </div>
                    </div>
                  }
                >
                  {clinicianList?.clinicians.map((clinicianObj, index) => (
                    <PsychologistCard key={index} clinicianDetail={clinicianObj} />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </CardWrapper>
        </ContentLayout>
        <EngageFooter />
        <TacklitFooter darkFooter />
      </div>
    </HelmetWrapper>
  );
};

export default PsychologistListing;
