import styles from './PsychologistAppointment.module.scss';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import { useGetAppointmentTypesQuery } from 'reduxToolkit/endpoints/scheduleServices/appointmentType';
import DeliveryTypeRadio from 'components/DeliveryTypeRadio/DeliveryTypeRadio';
import { useEffect, useState } from 'react';
import { Options, RadioOptions } from 'interfaces/common/general';
import DeliveryMode from '../DeliveryMode/DeliveryMode';
import { NextAvailability } from 'interfaces/Engage/publicProfile';
import classNames from 'classnames';
import { generateAvailableTimesList } from 'interfaces/Engage/bookingAppointment';
import DropdownSearchable from 'components/DropdownSearchable/DropdownSearchable';
import MultiSelectBadge from 'components/DropdownSearchable/components/MultiSelectBadge/MultiSelectBadge';
import { FIT_TIMES_LIST_LABELS, FitTimesList } from 'interfaces/Engage/fitFilter';
import TimeZoneDropdown from 'components/EngageComponent/TimeZoneDropdown/TimeZoneDropdown';
import AvailabilityCalendar from '../AvailabilityCalendar/AvailabilityCalendar';

interface PsychologistAppointmentProps {
  accountId: string;
  clinicianId: string;
  processNextAvailability?: NextAvailability[];
}

const PsychologistAppointment = ({ accountId, clinicianId, processNextAvailability }: PsychologistAppointmentProps) => {
  const { data: appointmentTypes, isLoading: appointmentTypesLoading } = useGetAppointmentTypesQuery(
    {
      accountId: accountId,
      clinicianId: clinicianId,
      excludeAllAssignmentMode: '1',
      excludeHideFromListing: '1'
      // claimType: clientAppointmentType // not doing this for now, it mainly use for only s1h
    },
    { skip: !accountId || !clinicianId }
  );

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<RadioOptions | undefined>(undefined);
  const [availableTimes, setAvailableTimes] = useState<Options[]>([]);

  useEffect(() => {
    if (appointmentTypes && appointmentTypes.length) {
      setSelectedAppointmentType({
        id: `${appointmentTypes[0]._id}-${appointmentTypes[0].slotCount || 0}`,
        value: appointmentTypes[0]._id || '',
        label: appointmentTypes[0].name
      });
    }
  }, [appointmentTypes]);

  const handleAvailableTime = (value: string) => {
    const isTimeInList = availableTimes.some((timeObj) => timeObj.value === value);
    const getTimesDetails = generateAvailableTimesList().find((timesObj) => timesObj.value === value);

    if (!isTimeInList && getTimesDetails) {
      setAvailableTimes((prev) => [
        ...prev,
        {
          value: getTimesDetails.value,
          label: getTimesDetails.label
        }
      ]);
    } else {
      setAvailableTimes((prev) => prev.filter((timeObj) => timeObj.value !== value));
    }
  };

  const handleRemoveAvailableTime = (value: string) => {
    setAvailableTimes((prev) => prev.filter((timeObj) => timeObj.value !== value));
  };

  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.content}>
          <div className={styles.title}>Select appointment</div>
          <div className={styles.desktopAppType}>
            {appointmentTypesLoading ? (
              <div>loading</div>
            ) : (
              <div className={styles.cardContent}>
                <DeliveryTypeRadio
                  vertical
                  radioClassName={styles.cardBox}
                  activeRadioClassName={styles.activeCardBox}
                  radioLabelClass={styles.cardLabel}
                  name={'appointmentType'}
                  options={
                    appointmentTypes?.map((appointmentType, idx) => {
                      const noNextAvailability = processNextAvailability
                        ? processNextAvailability.find(
                            (nextAvailability) => nextAvailability.appointmentTypeId === appointmentType._id
                          )?.availabilities.length === 0
                        : false;
                      const selectedAppointment = selectedAppointmentType?.id === `${appointmentType._id}-${idx}`;
                      return {
                        id: `${appointmentType._id}-${appointmentType.slotCount || 0}`,
                        value: appointmentType._id || '',
                        disabled: noNextAvailability,
                        label: (
                          <div
                            className={classNames(
                              styles.cardContainer,
                              noNextAvailability && styles.cardDisabledContainer
                            )}
                            key={idx}
                          >
                            <div
                              className={classNames(styles.cardInfo, selectedAppointment && styles.selectedCardInfo)}
                            >
                              <div
                                className={classNames(
                                  noNextAvailability ? styles.disabledName : styles.appointmentName
                                )}
                              >
                                {appointmentType.name}
                              </div>
                              {noNextAvailability ? (
                                <div className={styles.noAvailableWrapper}>
                                  <div className={`material-symbols-outlined ${styles.calendarIcon}`}>event_busy</div>
                                  No availability
                                </div>
                              ) : (
                                <div className={styles.otherInfoWrapper}>
                                  <div className={styles.infoItemBox}>
                                    <div className={`material-symbols-outlined ${styles.infoIcon}`}>query_builder</div>
                                    <div className={styles.infoText}>{appointmentType.duration} mins</div>
                                  </div>
                                  <div className={styles.infoItemBox}>
                                    <div className={`material-symbols-outlined ${styles.infoIcon}`}>
                                      monetization_on
                                    </div>
                                    <div className={styles.infoText}>{appointmentType.rate || 'No Fee'}</div>
                                  </div>
                                  <div className={styles.infoItemBox}>
                                    <div className={classNames(styles.infoText, styles.deliveryOptionWrapper)}>
                                      {selectedAppointment && (
                                        <DeliveryMode deliveryOptions={appointmentType.deliveryOptions} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {selectedAppointment && !noNextAvailability && appointmentType.description && (
                              <div className={styles.cardDesc}>{appointmentType.description}</div>
                            )}
                          </div>
                        )
                      };
                    }) || []
                  }
                  value={selectedAppointmentType?.id}
                  customOnChange={(value) => {
                    setSelectedAppointmentType(value);
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.mobileAppType}>
            <DropdownSearchable
              placeholder={'Select appointment types'}
              options={
                appointmentTypes?.map((appointmentType, idx) => {
                  const noNextAvailability = processNextAvailability
                    ? processNextAvailability.find(
                        (nextAvailability) => nextAvailability.appointmentTypeId === appointmentType._id
                      )?.availabilities.length === 0
                    : false;
                  return {
                    id: `${appointmentType._id}-${appointmentType.slotCount || 0}`,
                    value: appointmentType._id || '',
                    disabled: noNextAvailability,
                    label: appointmentType.name || '',
                    apptypeInfo: appointmentType
                  };
                }) || []
              }
              selected={selectedAppointmentType?.id}
              onSelect={(value, menu, ca) => {
                const getAppTypeDetail = appointmentTypes?.find((apptObj) => apptObj._id === value);
                setSelectedAppointmentType({
                  id: `${getAppTypeDetail?._id}-${getAppTypeDetail?.slotCount || 0}`,
                  value: getAppTypeDetail?._id || '',
                  label: getAppTypeDetail?.name || ''
                });
              }}
              className={styles.mobileAppTypeContainer}
              controllerClassName={styles.mobileAppTypeDropdownControllerWrapper}
              labelClassName={styles.mobileAppTypeLabel}
              menuListContainerClassName={styles.mobileAppTypeLabelWrapper}
              optionWrapperClassName={styles.mobileAppTypeOptionWrapper}
              itemClassName={styles.dropdownListWrapper}
              DisplayLabelChildren={(displayProps) => {
                return (
                  <div className={styles.mobileAppTypeDropdownWrapper}>
                    <div className={styles.mobileAppTypeTitle}>{displayProps.props.apptypeInfo.name}</div>
                    <div className={styles.mobileAppTypeInfoWrapper}>
                      <div className={styles.mobileAppTypeCommWrapper}>
                        <div className={styles.infoItemBox}>
                          <div className={`material-symbols-outlined ${styles.infoIcon}`}>query_builder</div>
                          <div className={styles.infoText}>{displayProps.props.apptypeInfo.duration} mins</div>
                        </div>
                        <div className={styles.infoItemBox}>
                          <div className={`material-symbols-outlined ${styles.infoIcon}`}>monetization_on</div>
                          <div className={styles.infoText}>{displayProps.props.apptypeInfo.rate || 'No Fee'}</div>
                        </div>
                      </div>
                      <div className={styles.infoItemBox}>
                        <div className={classNames(styles.infoText, styles.deliveryOptionWrapper)}>
                          <DeliveryMode deliveryOptions={displayProps.props.apptypeInfo.deliveryOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
              DropdownItemChildren={(displayProps) => (
                <div
                  className={classNames(
                    styles.mobileAppTypeDropdownListWrapper,
                    displayProps.props.apptypeInfo._id === selectedAppointmentType?.value && styles.selectedAppType
                  )}
                >
                  <div className={styles.mobileAppTypeTitle}>{displayProps.props.apptypeInfo.name}</div>
                  <div className={styles.mobileAppTypeInfoWrapper}>
                    <div className={styles.mobileAppTypeCommWrapper}>
                      <div className={styles.infoItemBox}>
                        <div className={`material-symbols-outlined ${styles.infoIcon}`}>query_builder</div>
                        <div className={styles.infoText}>{displayProps.props.apptypeInfo.duration} mins</div>
                      </div>
                      <div className={styles.infoItemBox}>
                        <div className={`material-symbols-outlined ${styles.infoIcon}`}>monetization_on</div>
                        <div className={styles.infoText}>{displayProps.props.apptypeInfo.rate || 'No Fee'}</div>
                      </div>
                    </div>
                    <div className={styles.infoItemBox}>
                      <div className={classNames(styles.infoText, styles.deliveryOptionWrapper)}>
                        <DeliveryMode deliveryOptions={displayProps.props.apptypeInfo.deliveryOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              hideActiveLine
            />
          </div>
        </div>
        <div className={styles.dividerLine} />
        <div className={styles.content}>
          <div className={styles.availabilityHeader}>
            <div className={styles.title}>Select day and time</div>
            <div className={styles.filterWrapper}>
              <DropdownSearchable
                placeholder={'Filter times to...'}
                options={generateAvailableTimesList()}
                selected={availableTimes.map((obj) => obj.value)}
                onSelect={handleAvailableTime}
                controllerClassName={styles.dropdownControllerWrapper}
                labelClassName={styles.label}
                menuListContainerClassName={styles.labelWrapper}
                DisplayLabelChildren={(displayProps) => (
                  <MultiSelectBadge
                    id={displayProps.props.value}
                    value={FIT_TIMES_LIST_LABELS[displayProps.props.value as keyof typeof FitTimesList]}
                    onRemoveBadge={handleRemoveAvailableTime}
                  />
                )}
                isMultiSelect
                showCheckBox
                hideActiveLine
              />
              <TimeZoneDropdown shortLabel />
            </div>
          </div>
          <AvailabilityCalendar
            accountId={accountId}
            clinicianId={clinicianId}
            selectedAppointmentType={appointmentTypes?.find(
              (apptObj) => apptObj._id === selectedAppointmentType?.value
            )}
            timeRangeFilter={availableTimes}
          />
        </div>
      </ContentLayout>
    </div>
  );
};

export default PsychologistAppointment;
