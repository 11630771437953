import { useEffect, useState } from 'react';
import styles from './FitFilterMobileItem.module.scss';
import classnames from 'classnames';
import { scrollToView } from 'utils/scrollToView';
import { Options } from 'interfaces/common/general';

interface FitFilterMobileItemProps {
  id: string;
  filterLabel: string;
  selectedValues?: Options[];
  optionList: Options[];
  isExpandProp: boolean;
  onChangeValue: (value: Options[]) => void;
  classname?: string;
  isMultipleSelect?: boolean;
}

const FitFilterMobileItem = ({
  id,
  filterLabel,
  selectedValues,
  optionList,
  isExpandProp,
  onChangeValue,
  classname,
  isMultipleSelect
}: FitFilterMobileItemProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(isExpandProp);

  const filterLabels = () => {
    if (!selectedValues?.length || isExpand) {
      return undefined;
    }

    if (!isMultipleSelect) {
      return <div className={styles.selectedValueLabel}>{selectedValues[0].label}</div>;
    } else {
      return selectedValues.map((item, index) => (
        <div key={index} className={styles.selectedValueLabel}>
          {item.label}
        </div>
      ));
    }
  };

  useEffect(() => {
    if (isExpand) {
      scrollToView({
        id
      });
    }
  }, [isExpand, id]);

  return (
    <div className={classnames(styles.container, classname)}>
      <div className={styles.filterHeader} onClick={() => setIsExpand(!isExpand)}>
        <div className={styles.labelWrapper}>
          <div className={isExpand ? styles.expandLabel : styles.label}>{filterLabel}</div>
          {filterLabels()}
        </div>
        <i className={`material-icons-outlined ${styles.icon}`}>
          {isExpand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        </i>
      </div>
      {isExpand && (
        <div className={styles.filterBody}>
          {optionList.map((item, index) => (
            <div
              key={index}
              className={classnames(
                styles.optionItem,
                selectedValues?.find((selectedValue) => selectedValue.value === item.value) && styles.selected
              )}
              onClick={() => {
                if (!isMultipleSelect) {
                  onChangeValue([item]);
                } else {
                  const isSelect = !(selectedValues || []).some((value) => value.value === item.value);
                  const newValue = isSelect
                    ? [...(selectedValues || []), item]
                    : (selectedValues || []).filter((value) => value.value !== item.value);
                  onChangeValue(newValue);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FitFilterMobileItem;
